<template>
    <div class="animated fadeIn">
        <h1>Stellenangebote <small class="ml-3"> von: {{ $route.query.company }}</small></h1>
        <div class="row align-items-center float-right col-3">
            <router-link
                    :to="{ path: '/company/editOffer/' + $route.params.id , query: { offer: -1, company: $route.query.company }}"
                    tag="button" class="btn margin-bottom-10 btn-primary btn-block">Stellenangebot anlegen
            </router-link>
        </div>
        <div class="row align-items-center float-right col-2 mr-0">
            <router-link :to="'/company'" tag="button" class="btn margin-bottom-10 btn-secondary btn-block"><i class="fa fa-arrow-circle-o-left"></i> Alle Unternehmen</router-link>
        </div>
        <div>
            <v-client-table ref="table" :api-mode="false" :data="offerEntries" :columns="columns" :options="options">
                <template slot="edit" slot-scope="props">
                    <router-link :to="{ path: '/company/editOffer/' + $route.params.id , query: { offer: props.row.id, company: $route.query.company }}">
                        <a class="fa fa-edit fa-lg"></a>
                    </router-link>
                    &nbsp;
                    <a class="fa fa-trash fa-lg text-primary" @click="warningModal = true, selectedOffer = props.index -1"></a>
                    &nbsp;
                </template>
            </v-client-table>
        </div>

        <!-- Modal Component -->
        <b-modal title="Unternehmen löschen?" class="modal-warning" v-model="warningModal" @ok="deleteOffer()" ok-variant="warning">
            Wollen Sie die Stellenangebote wirklich löschen?
        </b-modal>

    </div>
</template>

<script>
    import Vue from 'vue'
    import {ClientTable} from 'vue-tables-2'
    import {mapGetters} from "vuex";
    import VueNotifications from "vue-notifications";

    Vue.use(ClientTable)


    export default {
        name: 'offers',
        data () {
            return {
                warningModal: false,
                nrOfEntries: 0,
                columns: ['name', 'slug', 'edit'],
                options: {
                    headings: {
                        edit: '',
                        url: 'Webseite'
                    },
                    filterable: true,
                    sortable: [ 'title' ],
                    sortIcon: {
                        base: 'fa',
                        up: 'fa-sort-up',
                        down: 'fa-sort-down',
                        is: 'fa-sort'
                    },
                    texts: {
                        //count: 'Showing {from} to {to} of {count} records|{count} records|One record - Test',
                        count: '',
                        filterPlaceholder: 'Suche...',
                    },
                    pagination: {
                        nav: 'scroll'
                    },
                }
            }
        },
        computed: {
            ...mapGetters({
                offerEntries: 'company/getOfferEntries',
                company: 'company/getCompany',
            })
        },
        mounted() {
            this.$store.dispatch('company/loadCompany', this.$route.params.id)
        },
        methods: {
            deleteOffer() {
                this.$store.dispatch('company/deleteOffer', this.company.stellenangebotes[this.selectedOffer].id ).then(
                    response => {
                        this.$store.dispatch('company/loadCompany', this.$route.params.id)
                    }, error => {
                        this.notifyDeleteError()
                    }
                )
            },
        },

        notifications: {
            notifyDeleteSuccess: {
                type: VueNotifications.types.success,
                title: 'Stellenangebote wurde erfolgreich löschen',
            },
            notifyDeleteError: {
                type: VueNotifications.types.error,
                title: 'Fehler beim löschen der Stellenangebote'
            },
        }

    }
</script>
